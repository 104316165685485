import { DataTableHelpers } from './../../system/datatable/datatable-helper';
import { TableColumn } from '@swimlane/ngx-datatable';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  ViewChild,
  OnDestroy
} from '@angular/core';
import {
  ContractorService,
  ValidationService,
  ClientService,
  WorkflowService,
  WorkflowContextService
} from 'src/app/services';
import { Router } from '@angular/router';
import {
  Contractor,
  ContractorRegistration,
  Client,
  ContractorType,
  ContractorPhoneNumber,
  ContractorEmailAddress,
  ContractorAddress,
  Document
} from 'src/app/models';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContractorDocument } from 'src/app/models/contractor-document';
import { ContractorCustomFieldListComponent } from '../contractor-custom-field-list/contractor-custom-field-list.component';
import { ContractorDocumentCategory } from 'src/app/models/contractor-document-category';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from '../../filter-list/models/filterClasses';
import { RegistrationStatus } from 'src/app/models/registration';
import { ComponentIds } from 'src/app/models/component-ids';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-contractor-profile',
  templateUrl: './contractor-profile.component.html',
  styleUrls: ['./contractor-profile.component.css']
})
export class ContractorProfileComponent implements OnInit, OnDestroy {
  id = ComponentIds.CONTRACTOR_PROFILE;
  contractor: Contractor;
  form: UntypedFormGroup;
  newRegistrationForm: UntypedFormGroup;
  RegistrationStatus = RegistrationStatus;
  newRegistrationTypes: ContractorType[];
  newRegistrationType: ContractorType;
  newRegistrationTypeId: string = null;
  newRegistrationClientId: string = null;
  newRegistrationClient: Client;
  contractorClients: Client[];
  contractorClientStates: string[];
  documents: ContractorDocument[];
  documentCategories: ContractorDocumentCategory[];

  @Input() contractorId: string;
  @Input() clientId?: string;
  @ViewChild('customFields', { static: false })
  customFields: ContractorCustomFieldListComponent;

  clientSubscription: Subscription;
  contextClientId: string;

  public filterOptions: ItemSearchOptionField[];
  public columns: TableColumn[] = [];

  constructor(
    private _contractorSvc: ContractorService,
    private _clientSvc: ClientService,
    private _fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService,
    private _ref: ChangeDetectorRef,
    private _router: Router,
    private _workflowSvc: WorkflowService,
    private dtHelpers: DataTableHelpers,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {
    if (this._context.client) {
      this.contextClientId = this._context.client.id;
    } else {
      this.clientSubscription = this._context.client$.subscribe(client => {
        if (!client) {
          return;
        }

        this.contextClientId = client.id;
      });
    }

    this.columns = [
      {
        prop: 'registeredOn',
        name: 'Registration Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'effectiveOn',
        name: 'Effective Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'expiresOn',
        name: 'Expiration Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'approvedOn',
        name: 'Approved Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'type',
        name: 'Contractor Type'
      },
      {
        prop: 'paymentMethod',
        name: 'Payment Method'
      }
    ];
    if (!this.clientId) {
      this.columns.push({ prop: 'client', name: 'Jurisdiction' });
    }

    this.form = this._fb.group({
      faxNumber: ['', ValidationService.phoneNumberValidator],
      businessName: ['', Validators.required],
      cellNumber: ['', ValidationService.phoneNumberValidator],
      phoneNumber: ['', ValidationService.phoneNumberValidator],
      emailAddress: ['', ValidationService.emailValidator],
      contactFirstName: ['', Validators.nullValidator],
      contactLastName: ['', Validators.nullValidator]
    });

    this.newRegistrationForm = new UntypedFormGroup({
      newRegClient: new UntypedFormControl(null, [Validators.required]),
      newRegContractorType: new UntypedFormControl(null, [Validators.required])
    });

    if (this.contractorId) {
      this._contractorSvc
        .getContractorProfile(this.contractorId)
        .subscribe(result => {
          if (!result.contractor.contactEmail) {
            result.contractor.contactEmail = new ContractorEmailAddress({
              parent: this.contractorId
            });
          }
          if (!result.contractor.businessPhone) {
            result.contractor.businessPhone = new ContractorPhoneNumber({
              parentID: this.contractorId
            });
          }
          if (!result.contractor.contactFaxNumber) {
            result.contractor.contactFaxNumber = new ContractorPhoneNumber({
              parentID: this.contractorId
            });
          }
          if (!result.contractor.address) {
            result.contractor.address = new ContractorAddress({
              parent: this.contractorId
            });
          }
          if (!result.contractor.businessCell) {
            result.contractor.businessCell = new ContractorPhoneNumber({
              parentID: this.contractorId
            });
          }
          this.contractor = result.contractor;
          this.documents = this.contractor.documents;
          this.documentCategories = result.documentCategories;

          this.filterOptions = [
            {
              title: 'contractorId',
              options: [
                <ItemSearchOptionFieldOption>{
                  filterText: this.contractorId,
                  searchText: '',
                  strOperator: 'is',
                  selected: true
                }
              ]
            }
          ];

          this._ref.detectChanges();
        });
    }
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  addNewDocuments(documents: Document[]) {
    this._contractorSvc
      .saveContractorDocuments(
        this.contractorId,
        documents.map(d => {
          const cd: ContractorDocument = new ContractorDocument();
          cd.contractorId = this.contractorId;
          cd.documentName = d.name;
          cd.documentPath = d.path;
          cd.createdOn = new Date().toUTCString();
          cd.modifiedOn = new Date().toUTCString();
          return cd;
        })
      )
      .subscribe(() => {
        this._toastrSvc.success('Documents Added');
        this.loadDocuments();
      });
  }

  deleteDocument(documentId: string) {
    this._contractorSvc.deleteContractorDocument(documentId).subscribe(
      () => {
        this._toastrSvc.success('Document Deleted');
        this.loadDocuments();
      },
      err => {
        console.error(err);
      }
    );
  }

  saveDocument(doc: ContractorDocument) {
    this._contractorSvc.saveContractorDocument(doc).subscribe(() => {
      this._toastrSvc.success('Document Saved');
    });
  }

  selectType(e) {
    this.newRegistrationType = this.newRegistrationTypes.find(
      ct => ct.id === e.srcElement.value
    );
  }

  resetModal(e) {
    this.newRegistrationClient = null;
    this.newRegistrationClientId = null;
    this.newRegistrationType = null;
    this.newRegistrationTypes = null;
    this.newRegistrationTypeId = null;
  }

  async selectClient(e) {
    this.newRegistrationTypes = await this._contractorSvc
      .getAvailableContractorTypes(
        this.newRegistrationClientId,
        this.contractorId
      )
      .toPromise();
  }

  startApplication(e) {
    this._workflowSvc
      .startApplication(this.newRegistrationType.registerWorkflowId, false)
      .subscribe(result => {
        this._router.navigate([
          '/application/workflow-application',
          result.applicationId
        ]);
      });
  }

  async startNewReg(e) {
    if (!this.contractorClients) {
      this.contractorClients = await this._clientSvc
        .getClientsWithContractors()
        .toPromise();

      this.contractorClientStates = Array.from(
        new Set(this.contractorClients.map(c => c.state))
      );
    }
  }

  async loadDocuments() {
    this.documents = await this._contractorSvc
      .getContractorDocuments(this.contractorId)
      .toPromise();
  }

  save() {
    this.contractor.customFields = this.customFields.persistValues();

    // we don't need to send all of these across the web.
    this.contractor.registrations = [];
    this.contractor.documents = [];

    this._contractorSvc.saveContractorDetails(this.contractor).subscribe(() => {
      this._toastrSvc.success('Contractor Saved');
    });
  }
}
