import { ConditionTarget } from '../components/system/condition-builder/condition-builder.model';
import { Utilities } from '../services';
import { ItemSearchOptionFieldOption } from './../components/filter-list/models/filterClasses';
import { ReportDataSet } from './report-data-set';
import { ReportDataSetFieldMapping } from './report-data-set-field-mapping';

export enum ReportDataSetFieldDataType {
  String = 1,
  Date = 2,
  Decimal = 3,
  Integer = 4,
  GISData = 5
}

export class ListItem {
  text: string;
  value: string;
}

export class WorkflowFilter {
  workflowId: string;
  filtersList: ItemSearchOptionFieldOption[];
}

export class DataSetField {
  id: string;
  name: string;
  label: string;
  resultHeader: string;
  availableFilterItems: ListItem[];
  dataType: ReportDataSetFieldDataType;
  ordinal: number;
  mappings: ReportDataSetFieldMapping[];
  requireInput: boolean;
  showListForSelection: boolean;
  isParcelNumber: boolean;
  defaultSearchValue: string;
  outputFormula: ConditionTarget[];
  conditionalOutput?: boolean;
  searchFields: DataSetField[];

  constructor() {
    this.id = Utilities.generateId();
    this.mappings = [];
    this.dataType = ReportDataSetFieldDataType.String;
    this.outputFormula = null;
  }
}

export class FilterConfig {
  fields: DataSetField[];
  constructor(options?: Partial<FilterConfig>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class CustomReport {
  name: string;
  description: string;
  id: string;
  filterConfig: FilterConfig;
}

export class CustomReportFilterInputValue {
  name: string;
  startValue: string;
  endValue: string;
  operator: string;
}

export class CustomReportFilterInput {
  inputValues?: ItemSearchOptionFieldOption[];
  maxRows?: number;
}

export class CustomReportRequest {
  customReportId?: string;
  filterInput?: CustomReportFilterInput;
  dataSetConfig?: ReportDataSet;
}

export class CustomReportResult {
  outputFileURL: string;
  outputFileBinary: any;
}
